import React, { PropsWithChildren, useEffect } from "react"
import {
  StyledH2,
  StyledModal,
  StyledModalContentWrapper,
  StyledIcon,
  StyledIconButton, StyledAlertContent
} from "./StyledGlobalAlertModal";

import { faClose  } from '@fortawesome/pro-regular-svg-icons'
import { AlertQuery } from "../../pages/api/__generated__/alerts";
import {isNotNull} from "../../utils/nonNull";
import {GlobalAlert} from "../GlobalAlert/globalAlert";
import {html} from "../../utils/htmlParser";

type Maybe<T> = T | null | undefined

export type AlertProps = AlertQuery

export type PopUpModalProps = AlertProps & {
  title: string
  popUpStatus?: boolean | Maybe<string>[]
  onClose: () => void
  entries: AlertProps['entries']
}

export const GlobalAlertsPopUpModal: React.FC<PropsWithChildren<PopUpModalProps>> = ({title, popUpStatus, onClose, entries }) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose(event, 'buttonClick');
  };

  useEffect(() => {
    if (!popUpStatus) {
      setOpen(false);
    }
  }, [popUpStatus]);

  return (
    <div>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby={`Modal ${title}`}
        aria-describedby={`Modal ${title}`}
      >
        <StyledModalContentWrapper>
          <StyledIconButton onClick={handleButtonClick}>
            <StyledIcon icon={faClose} />
          </StyledIconButton>
          <StyledH2>{title}</StyledH2>

          <StyledAlertContent>
            {isNotNull(entries) &&
              entries.map((item, index) => {
                return (
                  item?.__typename === 'alert_default_Entry' &&
                  item?.status === 'live' &&
                  item.alert?.[0]?.alertContent &&
                  <GlobalAlert
                      key={index}
                      cookieAlert={false}
                      readMore={true}
                      mobileTitle={item?.title as string}
                      hashInfo={item?.alert?.[0]?.alertContent} type={item?.alert?.[0]?.style === 'primary' || item.alert?.[0]?.style === 'pleaseSelect' ? 'primary' : (item.alert?.[0]?.style === 'dark' ? 'dark' : 'light')}
                      severity={item?.alert?.[0]?.style === 'primary' || item.alert?.[0]?.style === 'pleaseSelect' ? 'info' : 'warning'}
                      display={true}
                  >
                    {html(item.alert?.[0]?.alertContent)}
                  </GlobalAlert>
                )
              })
            }
          </StyledAlertContent>
        </StyledModalContentWrapper>
      </StyledModal>
    </div>
  )
}