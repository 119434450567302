import React, { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

interface AlertsContextType {
  isAlertViewed: boolean;
  setAlertViewed: () => void;
}

const AlertsContext = createContext<AlertsContextType | undefined>(undefined);

export const AlertsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAlertViewed, setIsAlertViewed] = useState(false);

  useEffect(() => {
    const alertViewed = Cookies.get('alertsViewed')
    setIsAlertViewed(!!alertViewed)
  }, []);

  const setAlertViewed = () => {
    Cookies.set('alertsViewed', 'true', { expires: 7 })
    setIsAlertViewed(true)
  };

  return (
    <AlertsContext.Provider value={{ isAlertViewed, setAlertViewed }}>
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlertsContext = (): AlertsContextType => {
  const context = useContext(AlertsContext)
  if (!context) {
    throw new Error('useAlertsContext must be within an AlertsProvider')
  }
  return context;
};