/* eslint-disable unicorn/no-nested-ternary */
import {Container, Grid} from '@material-ui/core'
import React, {ComponentProps, PropsWithChildren, useContext} from 'react'
import styled from 'styled-components'
import {PrimaryNavigationContext} from '../PrimaryNavigation/PrimaryNavigation'
import {StyledDropdownPanel} from '../PrimaryNavigation/StyledNavContainers'
import {StyledPanelWrapper} from "./StyledPanelWrapper";

export type NavDropdownPanelProps = ComponentProps<typeof Grid> & {
  show?: boolean
}

export const NavDropdownPanel: React.FC<PropsWithChildren<NavDropdownPanelProps>> = ({ show=false, children, ...props }) => {
  const { secondary } = useContext(PrimaryNavigationContext)
  return (
    <StyledPanelWrapper $show={show} $secondary={secondary} {...props}>
      <StyledPanelFullContainer>
        <Container maxWidth={'lg'}>
          <StyledDropdownPanel
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-end">
            { children }
          </StyledDropdownPanel>
        </Container>
      </StyledPanelFullContainer>
    </StyledPanelWrapper>
  )
}

const StyledPanelFullContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  cursor: auto;

  ${props => props.theme.breakpoints.up('md')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(45)};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => props.theme.typography.pxToRem(45)}), 0 100%);
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(90)};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => props.theme.typography.pxToRem(90)}), 0 100%);
  }
`
