import React, { PropsWithChildren, useEffect, useMemo, useState } from "react"
import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { Icon } from "../../icon/icon"
import {
  GenesysChatPlaceholder,
  GenesysChatPlaceholderBtn,
  GlobalButtonWrapper,
  StyledCloseButton,
  StyledGlobalButton,
  StyledPopUp,
} from "./StyledQuickExit"

export const QuickExitFragment: React.FC<PropsWithChildren> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const [isPopupTextVisible, setPopupTextVisible] = useState<boolean>(false)
  const [isFadingOut, setIsFadingOut] = useState<boolean>(false)
  const [qeOffset, setQEOffset] = useState(0)
  const [qePopupOffset, setQEPopupOffset] = useState(0)

  const globalQEObserver = useMemo(() => {
    if (typeof ResizeObserver !== "undefined") {
      return new ResizeObserver(() => {
        const alerts = document.querySelector(".alert-container")

        if (alerts) {
          setQEOffset(alerts.clientHeight + 28)
          setQEPopupOffset(alerts.clientHeight + 92)
        }
      })
    }
  }, [])

  useEffect(() => {
    const alerts = document.querySelector(".alert-container")

    if (alerts && globalQEObserver) {
      globalQEObserver.observe(alerts)
    }

    return () => {
      if (globalQEObserver) {
        globalQEObserver.disconnect()
      }
    }
  }, [globalQEObserver])

  useEffect(() => {
    const hidden = localStorage.getItem("qe_visibility")
    if (hidden === "true") {
      setIsVisible(false)
    } else {
      const showTimer = setTimeout(() => {
        setPopupTextVisible(true)
        const hideTimer = setTimeout(() => {
          setIsFadingOut(true)
          setTimeout(() => setPopupTextVisible(false), 500)
        }, 10000)
        return () => clearTimeout(hideTimer)
      }, 3000)

      return () => clearTimeout(showTimer)
    }
  }, [])

  const handleQuickExit = () => {
    window.open("http://www.bom.gov.au/", "_blank", "noopener, noreferrer")
    window.history.replaceState(null, "", "/")
    window.location.replace("https://google.com.au/")
  }

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem("qe_visibility", "true")
  };

  const handleMouseEnter = () => {
    setPopupTextVisible(true)
    setIsFadingOut(false)
  };

  const handleMouseLeave = () => {
    setIsFadingOut(true)
    setTimeout(() => setPopupTextVisible(false), 500)
  };

  if (!isVisible) {
    return null
  }

  return (
    <>
      {isPopupTextVisible && (
        <StyledPopUp isFadingOut={isFadingOut} style={{ bottom: qePopupOffset }}>
          {`If you need to leave our website quickly and safely, use this quick exit button `} <br />
          <a
            href="https://www.creditunionsa.com.au/help-and-support/financial-abuse"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about financial abuse
          </a>.
        </StyledPopUp>
      )}
      <GlobalButtonWrapper
        id={"quick-exit"}
        style={{ bottom: qeOffset }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StyledGlobalButton onClick={handleQuickExit}>
          {`Quick exit`}
        </StyledGlobalButton>
        <StyledCloseButton onClick={handleClose}>
          <Icon icon={faXmark} />
        </StyledCloseButton>
      </GlobalButtonWrapper>
      <GenesysChatPlaceholder>
        <GenesysChatPlaceholderBtn />
      </GenesysChatPlaceholder>
    </>
  );
};
