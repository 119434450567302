import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "../icon/icon";
import { GlobalAlertsPopUpModal } from "./globalAlertModal";
import { AlertQuery } from '../../pages/api/__generated__/alerts'
import {
  StyledGlobalAlertBtn,
  StyledGlobalAlertWrapper, StyledRedBubble
} from "./StyledGlobalAlertTrigger";
import { useAlertsContext } from "./AlertContext";

export type AlertProps = AlertQuery

export const GlobalAlertTrigger: React.FC<PropsWithChildren<AlertProps>> = ({ entries }) => {
  const [ alertOffset, setAlertOffset] = useState(0)
  const [ isModalOpen, setIsModalOpen] = useState(false)
  const { isAlertViewed, setAlertViewed } = useAlertsContext();

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    openModal();
    setAlertViewed();
  };

  const globalAlertsObserver = useMemo(() => {
    if (typeof ResizeObserver !== 'undefined') {
      return new ResizeObserver(() => {
        const alerts = document.querySelector('.alert-container')

        if (alerts) {
          setAlertOffset(alerts.clientHeight + 12)
        }
      })
    }
  }, [])

  useEffect(() => {
    const alerts = document.querySelector('.alert-container')

    if (alerts && globalAlertsObserver) {
      globalAlertsObserver.observe(alerts)
    }

    return () => {
      if (globalAlertsObserver) {
        globalAlertsObserver.disconnect()
      }
    }
  }, [globalAlertsObserver]);

  return (
    <>
      {isModalOpen && (<GlobalAlertsPopUpModal
        title={`Latest alerts`}
        popUpStatus={true}
        onClose={closeModal}
        entries={entries}
      />)}
      <StyledGlobalAlertWrapper style={{bottom: alertOffset}}>
        <StyledGlobalAlertBtn onClick={handleButtonClick}>
          <Icon icon={faBell}/>
          {!isAlertViewed && <StyledRedBubble />}
        </StyledGlobalAlertBtn>
      </StyledGlobalAlertWrapper>
    </>
  )
}