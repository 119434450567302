import React, { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'
import { AlertQuery } from '../../../pages/api/__generated__/alerts'
import { html } from '../../../utils/htmlParser'
import { GlobalAlert } from '../../GlobalAlert/globalAlert'
import { WebchatButton } from '../../webchatButton/WebchatButton'

export type AlertProps = AlertQuery

export const AlertsFragment: React.FC<PropsWithChildren<AlertProps>> = ({ globalSet, entries }) => {
  const portal = typeof document !== 'undefined' ? document.querySelector('.alert-container') : null

  const alertContainers = (
    <>
      {typeof window !== 'undefined' &&
    <>
      {
        globalSet?.__typename === 'alertsTab_GlobalSet' &&
        globalSet?.globalAlerts?.[0]?.cookieAlert &&
        <GlobalAlert
            cookieAlert={true}
            readMore={false}
            buttonText="Accept"
            hashInfo={globalSet?.globalAlerts?.[0]?.cookieAlert} type={'dark'}
            severity={'info'}
        >
          {html(globalSet?.globalAlerts?.[0]?.cookieAlert)}
        </GlobalAlert>
      }
    </>
      }
    </>
  )

  return portal ? createPortal(alertContainers, portal) : alertContainers
}
