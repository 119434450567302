import React, {PropsWithChildren, useRef, useState} from 'react'
import { AlertQuery } from '../../pages/api/__generated__/alerts'
import { html } from '../../utils/htmlParser'
import { isNotNull } from '../../utils/nonNull'
import { GlobalAlert } from '../GlobalAlert/globalAlert'
import styled from "styled-components"
import { GlobalAlertsPopUpModal } from "../GlobalAlertModal/globalAlertModal";
import { ButtonProps } from "../button/button";
import { StyledButton } from "../GlobalAlertModal/StyledGlobalAlertModal";
import { useAlertsContext } from "../GlobalAlertModal/AlertContext";
import { createPortal } from "react-dom";

export type AlertProps = AlertQuery

export type CookieProps = { $sticky?: boolean; $visibleHeader?: boolean; $scrollingUp?: boolean }

export const StyledCookieAlertsContainer = styled.div<CookieProps>`
  width: 100%;
  z-index: 1000;
  transition-property: margin-top, transform, background-color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-in-out !important;
  position: relative;
`

export const CookieBannerAlertsFragment: React.FC<PropsWithChildren<AlertProps>> = ({ entries }) => {
  const portal = typeof document !== 'undefined' ? document.querySelector('.global-alerts-container') : null

  const [ isModalOpen, setIsModalOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isAlertViewed, setAlertViewed } = useAlertsContext();

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    openModal();
    setAlertViewed();
  };

  const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <StyledButton {...props} ref={ref}>
      {props.children}
    </StyledButton>),
  )

  const alertContainers = (
    <>
      {isModalOpen && (<GlobalAlertsPopUpModal
        title={`Latest alerts`}
        popUpStatus={true}
        onClose={closeModal}
        entries={entries}
      />)}
      <StyledCookieAlertsContainer ref={containerRef} className={`cookie-banner-alerts`}>
        {typeof window !== 'undefined' &&
          <>
            {isNotNull(entries) &&
              entries
                .filter((item) => item?.__typename === "alert_default_Entry" && item?.featuredAlerts)
                .slice(0, 2)
                .map((item, index) => (
                  item?.__typename === 'alert_default_Entry' &&
                  item?.status === 'live' &&
                  item.alert?.[0]?.alertContent && (
                    <GlobalAlert
                      key={index}
                      cookieAlert={false}
                      readMore={true}
                      mobileTitle={item?.title as string}
                      hashInfo={item?.alert?.[0]?.alertContent}
                      type={
                        item?.alert?.[0]?.style === 'primary' ||
                        item.alert?.[0]?.style === 'pleaseSelect'
                          ? 'primary'
                          : item.alert?.[0]?.style === 'dark'
                            ? 'dark'
                            : 'light'
                      }
                      severity={
                        item?.alert?.[0]?.style === 'primary' || item.alert?.[0]?.style === 'pleaseSelect'
                          ? 'info'
                          : 'warning'
                      }
                    >
                      {html(item.alert?.[0]?.alertContent)}
                    </GlobalAlert>
                  )
                ))}
              <ButtonLink
                  primary
                  buttonColor="orange"
                  onClick={handleButtonClick}
              >
                  View latest alerts
              </ButtonLink>
          </>
        }
      </StyledCookieAlertsContainer>
    </>
  )

  return portal ? createPortal(alertContainers, portal) : alertContainers
}
