import Collapse from '@material-ui/core/Collapse'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AlertProps } from '@material-ui/lab'
import hash from 'object-hash'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import {
  StyledAlert,
  StyledAlertContent,
  StyledAlertTitle, StyledAlertTitleDesktop,
  StyledContainer,
  StyledReadMoreorLessButton,
  StyledButton,
  StyledCookieAlertContent
} from "./StyledGlobalAlert";

export type AlertNoticeProps = AlertProps & {
  type?: 'light' | 'dark' | 'primary'
  hashInfo: string
  buttonText?: string
  mobileTitle?: string
  readMore: boolean
  cookieAlert: boolean
  severity: 'info' | 'warning'
  display?: boolean | undefined
};

export const GlobalAlert: React.FC<PropsWithChildren<AlertNoticeProps>> = ({ children, type, buttonText, hashInfo, mobileTitle, readMore, cookieAlert, severity, display, ...props }) => {
  const theme = useTheme()
  const [displayState, setDisplay] = useState(false)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    const hashedChild = hash(hashInfo)

    if (localStorage.getItem(hashedChild) !== 'hidden') {
      setDisplay(true)
    } else {
      if (display) {
        setDisplay(true)
      }
    }
  }, [hashInfo])

  const setDisplayStorage = useCallback((unhashedChild) => {
    localStorage.setItem(hash(unhashedChild), 'hidden')

    if (display) {
      setDisplay(true)
    } else {
      setDisplay(false)
    }
  }, [])

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChange = () => {
    setOpen((prev) => !prev)
  }

  return (
    <StyledContainer $type={type} maxWidth={false}>
      <StyledContainer maxWidth="lg">
        {
          isMobile ? (
            <StyledAlert $display={displayState} onClose={() => {setDisplayStorage(hashInfo)}} severity={severity} $type={type}
              action={
                buttonText ? (
                  <StyledButton onClick={()=> setDisplayStorage(hashInfo)} $color={type} primary buttonColor="orange">
                    {buttonText}
                  </StyledButton>) : null
              }
              { ...props }>
              <StyledAlertTitle $type={type}>{mobileTitle}</StyledAlertTitle>
              { readMore && !open &&
                <StyledReadMoreorLessButton text onClick={()=> handleChange()}>Read more</StyledReadMoreorLessButton>
              }
              { cookieAlert ? (
                <StyledCookieAlertContent>
                  { children }
                </StyledCookieAlertContent>
              ) : (
                <Collapse in={open}>
                  <StyledAlertContent> 
                    {children}
                  </StyledAlertContent>
                  { readMore && open &&
                <StyledReadMoreorLessButton text onClick={()=> handleChange()}>Read less</StyledReadMoreorLessButton>
                  }
                </Collapse>
              )}
            </StyledAlert>
          ) : (
            <StyledAlert $display={displayState} onClose={() => {setDisplayStorage(hashInfo)}} severity={severity} $type={type}
              action={
                buttonText ? (
                  <StyledButton onClick={()=> setDisplayStorage(hashInfo)} $color={type} primary buttonColor="orange">
                    {buttonText}
                  </StyledButton>) : null
              }
              { ...props }>
              <StyledAlertTitleDesktop $type={type}>{mobileTitle}</StyledAlertTitleDesktop>
              {children}
            </StyledAlert>
          )
        }
      </StyledContainer>
    </StyledContainer>
  )
}
