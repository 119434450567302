import styled from "styled-components";
import {alpha} from "@material-ui/core/styles";
import {StyledNavItem} from "../PrimaryNavigation/NavItem";

export const StyledPanelWrapper = styled.div<{ $show?: boolean; $secondary?: boolean }>`
    visibility: ${props => props.$show ? 'visible' : 'hidden'};
    opacity: ${props => props.$show ? 1 : 0};
    will-change: opacity;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: ${props => props.theme.typography.pxToRem(104)};
    left: 0;
    right: 0;
    z-index: 9;
    filter: drop-shadow(0 ${props => props.theme.typography.pxToRem(2)} 0 ${alpha('#707070', 0.3)});
    -webkit-transform: translate3d(0, 0, 0);

    ${StyledNavItem}:focus-within > & {
        visibility: visible;
        opacity: 1;
    }
`