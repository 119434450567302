import styled from "styled-components";
import { IconButton } from "@material-ui/core";
export const StyledGlobalAlertWrapper = styled.div`
    position: fixed;
    z-index: 1200;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 12px;
    right: 85px;
`

export const StyledGlobalAlertBtn = styled(IconButton)`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: white;
    border: 2px solid #ED8B00;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        font-size: 24px;
        margin-top: -${props => props.theme.typography.pxToRem(2)};
        fill: #1C1F2A;
    }

    &:hover {
        background-color: #FFB81C;

        svg {
            fill: #fff;
        }
    }
`

export const StyledRedBubble = styled.div`
    position: absolute;
    width: 11px;
    height: 11px;
    background: #D83B00;
    right: 13px;
    top: 13px;
    border-radius: 50%;
`