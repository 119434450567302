import { Button, IconButton } from "@material-ui/core";
import styled from "styled-components";

export const StyledGlobalButton = styled(Button)`
  font-size: ${props => props.theme.typography.pxToRem(12)};
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(15)};
  color: ${props => props.theme.palette.primary.dark};
  border: none;
  background-color: #FFF7E7;
  position: relative;
  border-radius: ${props => props.theme.typography.pxToRem(100)};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

  &::after {
    content: '';
    background: url(/images/arrow-up-right-from-square-solid.svg) 0 0 no-repeat;
    background-size: contain;
    width: ${props => props.theme.typography.pxToRem(10)};
    height: ${props => props.theme.typography.pxToRem(10)};
    margin-left: ${props => props.theme.typography.pxToRem(5)};
  }

  &:hover {
    background-color: #FFB81C;
    border: none;
    color: ${props => props.theme.palette.primary.dark};
  }
    
  .MuiButton-startIcon svg {
    position: relative;
    top: -1px;
    font-size: 1.2em;
    color: ${props => props.theme.palette.primary.dark};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 15px;
    padding: 0 45px 0 15px;
    height: 40px;

    .MuiButton-startIcon svg {
      position: relative;
      top: -1px;
      font-size: 1em;
    }
  }

  &::after {
    width: 15px;
    height: 15px;
  }
`

export const StyledCloseButton = styled(IconButton)`
  padding: ${props => props.theme.typography.pxToRem(3)};
  margin: 0;
  position: absolute;
  right: ${props => props.theme.typography.pxToRem(10)};
  top: ${props => props.theme.typography.pxToRem(7)};

  ${props => props.theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(13)};
    top: 8px;
  }

  .MuiIconButton-label {
    svg {
      fill: #EF5C2E;
      width: ${props => props.theme.typography.pxToRem(14)};
      height: ${props => props.theme.typography.pxToRem(14)};

      ${props => props.theme.breakpoints.up('sm')} {
        width: 18px;
        height: 18px;
      }
    }
  }

    &:hover {
      background-color: #FFB81C;
      
      svg {
          fill: #fff;
      }
    }
`

export const StyledPopUp = styled.div<{ isFadingOut: boolean }>`
  position: fixed;
  z-index: 1200;
  bottom: ${props => props.theme.typography.pxToRem(80)};
  right: ${props => props.theme.typography.pxToRem(70)};
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: ${props => props.theme.typography.pxToRem(10)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.primary.dark};
  line-height: ${props => props.theme.typography.pxToRem(20)};
  opacity: ${props => (props.isFadingOut ? 0 : 1)};
  width: 100%;
  max-width: ${props => props.theme.typography.pxToRem(300)};
  transition: all 0.5s ease;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    
  ${props => props.theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(100)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
      right: ${props => props.theme.typography.pxToRem(90)};
  }

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -16px;
    background: url(/images/arrow-down.svg) 0 0 no-repeat;
    left: calc(50% - 8px);
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 16px;
    line-height: 26px;
    max-width: 300px;
    padding: 15px;
    bottom: 90px;
      
    &::after {
        left: calc(50% - 8px);
    }
  }
`

export const GlobalButtonWrapper = styled.div`
  position: fixed;
  z-index: 1200;
  right: 165px;
  transition: bottom 0.5s ease;
`

export const GenesysChatPlaceholder = styled.div`
  position: fixed;
  z-index: 1200;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 12px;
  right: 20px;
`

export const GenesysChatPlaceholderBtn = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgb(13, 110, 253);
`
